import { useState } from 'react';
import './Upload.css';
import axios from 'axios';

const correctPass = process.env.REACT_APP_PASSWORD;
const baseUrl = process.env.REACT_APP_BACKEND_URL;

export default function Upload() {
	const [images, setImages] = useState<File[]>([]);
	const [uploading, setUploading] = useState<boolean>(false);
	const [uploadProgress, setUploadProgress] = useState<number>(0);
	const [password, setPassword] = useState<string>('');

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		const files = Array.from(e.target.files);
		setImages(files);
	};

	const uploadImages = async () => {
		if (password !== correctPass) {
			alert('Incorrect password!');
			return;
		}
		try {
			setUploading(true);

			for (let i = 0; i < images.length; i++) {
				const image = images[i];
				const formData = new FormData();
				formData.append('image', image);
				try {
					// Upload using axios

					const res = await axios.post(`${baseUrl}/image`, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					});

					console.log({ resData: res?.data });

					setUploadProgress((i + 1) * (100 / images.length));
				} catch (error) {
					console.error('Error uploading image:', error);
				}
			}
			setUploading(false);
			setUploadProgress(0);
            setImages([]);

			alert('Images uploaded successfully!');
		} catch (error) {
			console.error('Error uploading image:', error);
			alert('Error uploading images');
		} finally {
			setUploading(false);
			setUploadProgress(0);
		}
	};

	// const fakeUploadImage = (formData: FormData) => {
	// 	return new Promise<void>((resolve, reject) => {
	// 		setTimeout(() => {
	// 			// Simulating success after 1 second
	// 			resolve();
	// 		}, 1000);
	// 	});
	// };

	return (
		<div className='container'>
			<h2>Upload Images</h2>
			<input type='file' multiple onChange={handleImageChange} />
			{images.length > 0 && (
				<div>
					<h2>Preview</h2>
					<div className='preview-container'>
						{images.map((image, index) => (
							<img
								key={index}
								src={URL.createObjectURL(image)}
								alt={`Preview ${index}`}
								className='preview-image'
							/>
						))}
					</div>
					<button onClick={uploadImages} disabled={uploading}>
						Upload Images
					</button>
				</div>
			)}
			{uploading && (
				<div>
					<h2>Upload Progress</h2>
					<progress value={uploadProgress} max='100' />
				</div>
			)}
			<div className='password-input-container'>
				<label htmlFor='password' className='password-label'>
					Password:
				</label>
				<input
					type='password'
					id='password'
					placeholder='Enter Password'
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className='password-input'
				/>
			</div>
		</div>
	);
}
