import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Gallery from './components/Gallery';
import Upload from './components/Upload';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Gallery />,
	},
	{
		path: '/admin-1274',
		element: <Upload />,
	},
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
